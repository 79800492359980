<template>
  <el-dialog
    id="checkout-error-dialog"
    :visible="visible"
    title="SI È VERIFICATO UN ERRORE..."
    append-to-body
    width="600px"
    @close="$emit('close')"
  >
    <span>{{ $t('message.Ops******_errore_non_previsto') }}</span>
    <el-row slot="footer">
      <el-button type="primary" @click="$emit('close')">{{ $t('message.Chiudi') }}</el-button>
      <el-button type="primary" @click="$emit('cart-checkout')"
        >{{ $t('message.Riprova') }}</el-button
      >
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>