<template>
  <el-container id="cfg-unco-usa" ref="cfg-unco-usa">
    <div class="logo" style="z-index:100">
      <img alt="unco" src="./assets/images/logo_unco.png" />
    </div>
    <transition name="slide-left-out">
      <el-aside v-show="menuVisible">
        <el-row v-if="isMobileOnly" class="return" type="flex">
          <ArrowLeft
            class="return-icon"
            :size="36"
            fillColor="#161615"
            @click="emitCloseMenu()"
          />
          <span class="return-text" @click="emitCloseMenu()">Back</span>
        </el-row>
        <Menu />
      </el-aside>
    </transition>
    <el-main ref="canvas">
      <Canvas />
      <ActionBar class="action-bar" />
    </el-main>
  </el-container>
</template>

<script>
  import { EventBus } from "./plugins/EventBus";

  import Canvas from "./components/Canvas.vue";
  import Menu from "./components/LeftMenu.vue";
  import ActionBar from "./components/MainActionBar.vue";
  import ArrowLeft from "vue-material-design-icons/ChevronLeft.vue";

  export default {
    name: "App",
    components: {
      Menu,
      Canvas,
      ActionBar,
      ArrowLeft,
      // AddToCartDialog,
    },
    data() {
      return {
        menuVisible: true,
        // isMobile: false,
        // isTablet: false,
        // isMobileOnly: false,
      };
    },
    mounted() {
      this.isMobile = EventBus.deviceIsMobile;
      console.log(this.isMobile, "mobile");
      this.isTablet = EventBus.deviceIsTablet;
      console.log(this.isTablet, "tablet");
      this.isMobileOnly = EventBus.deviceIsMobileOnly;
      console.log(this.isMobileOnly, "mobileOnly");
      if (this.isMobile || this.isTablet || this.isMobileOnly) {
        this.menuVisible = false;
        this.mainMenuOpen = false;

        let canvas = this.$refs.canvas;
        // canvas.$el.style.maxWidth = "100vh";
        // canvas.$el.style.width = "100vh";
        // console.log("CANVAS", canvas);

        canvas.$el.style.maxHeight = "100vh";
        canvas.$el.style.height = "calc(80% - 55px)";
        // canvas.$el.style.maxHeight = window.innerHeight;
        // canvas.$el.style.height = `calc(${window.innerHeight} - 55px)`;
        canvas.$el.style.marginTop = "10vh";
        // canvas.$el.style.marginBottom = "5vh";
        // canvas.$el.style.position = "relative";
        // canvas.$el.style.bottom = "0";
      } else {
        this.menuVisible = true;
        this.mainMenuOpen = true;
      }

      EventBus.$on("close-menu", () => {
        this.menuVisible = false;
      });
      EventBus.$on("open-menu", () => {
        this.menuVisible = true;
      });
      let e = this.$refs["cfg-unco-usa"].$el;
      console.log(this.$refs);

      // if (this.isMobileOnly) {
      //   e.style.height = `${window.innerHeight}px`; //`${e.offsetWidth * (16 / 9)}px`;
      // } else if (this.isMobile || this.isTablet) {
      //   e.style.height = `${e.offsetWidth * (4 / 3)}px`;
      // }
      const w = window.innerWidth;
      const h = window.innerHeight;
      e.style.height = `${e.offsetWidth * (h / w)}px`;
    },
    methods: {
      emitCloseMenu() {
        EventBus.$emit("close-menu");
        this.mainMenuOpen = false;
      },
    },
    computed: {
      isMobile: {
        get() {
          return this.$store.state.isMobile;
        },
        set(val) {
          this.$store.state.isMobile = val;
        },
      },
      isTablet: {
        get() {
          return this.$store.state.isTablet;
        },
        set(val) {
          this.$store.state.isTablet = val;
        },
      },
      isMobileOnly: {
        get() {
          return this.$store.state.isMobileOnly;
        },
        set(val) {
          this.$store.state.isMobileOnly = val;
        },
      },
      mainMenuOpen: {
        get() {
          return this.$store.state.mainMenuOpen;
        },
        set(val) {
          this.$store.state.mainMenuOpen = val;
        },
      },
    },
    watch: {
      menuVisible() {
        setTimeout(() => {
          EventBus.emitPageResize();
        }, 350);
      },
    },
  };
</script>

<style lang="less">
  #cfg-unco-usa {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f4f4f4;
    text-align: center;
    color: #2c3e50;
    max-width: 100vw !important;
    // Inherit è utilizzato per la messa in produzione
    // max-height: inherit !important; // se non funziona controllare height in @media @mobile
    // height: 100% !important;
    // I 100vh sono utilizzati per lavorare sullo sviluppo
    max-height: 100vh !important;
    width: 100% !important;
    // height: 100%;
    // height: calc($width * (3 / 4)) !important;
    position: relative;
    .return {
      align-items: center;
      background-color: @--border-color-base;
      .return-icon,
      .return-text {
        cursor: pointer;
      }
    }

    .el-main {
      padding: 0;
    }

    .el-aside {
      box-shadow: 0px 0px 30px #04040533;
      overflow: hidden;
      z-index: 9;
      width: @--menu-width !important;
      max-height: 100%;
    }

    .slide-left-out-enter-active {
      transition: all 0.3s ease;
    }
    .slide-left-out-leave-active {
      transition: all 0.3s;
    }
    .slide-left-out-enter,
    .slide-left-out-leave-to {
      transform: translateX(-360px);
      opacity: 0;
    }

    @media (max-width: 1200px) {
      .logo {
        img {
          height: 80px;
        }
      }
    }

    @media (max-width: 992px) {
      .logo {
        img {
          height: 50px;
        }
      }
    }
    @media (min-width: 1201px) {
      .logo {
        img {
          height: 125px;
        }
      }
    }

    .logo {
      position: absolute;
      left: 50%;
      top: 0;
    }
  }

  @media @mobile {
    #cfg-unco-usa {
      // Inherit è utilizzato per la messa in produzione
      // max-height: inherit !important;
      // I 100vh sono utilizzati per lavorare sullo sviluppo
      // height: 100vh !important;
      .el-aside {
        height: 100% !important;
        width: @--menu-width-mobile !important;
      }
    }
  }
</style>
