export default [
  {
    model: 'UN-001',
    height: 43.60,
    angle: 10,
  },
  {
    model: 'UN-002',
    height: 42.10,
    angle: 10,
  },
  {
    model: 'UN-003',
    height: 42.70,
    angle: 8,
  },
  {
    model: 'UN-004',
    height: 42.50,
    angle: 10,
  },
  {
    model: 'UN-005',
    height: 49.20,
    angle: 10,
  },
  {
    model: 'UN-006',
    height: 45.50,
    angle: 10,
  },
  {
    model: 'UN-007',
    height: 47.40,
    angle: 10,
  },
  {
    model: 'UN-008',
    height: 47.90,
    angle: 10,
  },
  {
    model: 'UN-009',
    height: 41.90,
    angle: 10,
  },
  {
    model: 'UN-010',
    height: 43.30,
    angle: 10,
  },
  {
    model: 'UN-011',
    height: 43.80,
    angle: 10,
  },
  {
    model: 'UN-012',
    height: 46.60,
    angle: 10,
  },
  {
    model: 'UN-013',
    height: 52.70,
    angle: 10,
  },
  {
    model: 'UN-014',
    height: 49.70,
    angle: 10,
  },
  {
    model: 'UN-015',
    height: 48.50,
    angle: 10,
  },
  {
    model: 'UN-016',
    height: 44.20,
    angle: 10,
  },
  {
    model: 'UN-017',
    height: 36.20,
    angle: 10,
  },
  {
    model: 'UN-018',
    height: 40.60,
    angle: 10,
  },
  {
    model: 'UN-019',
    height: 48.90,
    angle: 10,
  },
  {
    model: 'UN-020',
    height: 45.30,
    angle: 10,
  },
  {
    model: 'UN-021',
    height: 47.90,
    angle: 10,
  },
  {
    model: 'UN-022',
    height: 45.30,
    angle: 10,
  },
  {
    model: 'UN-023',
    height: 43.80,
    angle: 10,
  },
  {
    model: 'UN-024',
    height: 46.80,
    angle: 10,
  },
  {
    model: 'UN-025',
    height: 41.5,
    angle: 10,
  },
]
