<template>
  <el-dialog
    id="confirm-checkout-dialog"
    append-to-body
    :visible="visible"
    @close="$emit('close')"
    width="600px"
    class="text-center"
  >
    <template slot="title">
      <span>{{ $t("message.Sei_sicuro_di_procedere_con_il_checkout?") | upperCase }}</span>
    </template>
    <el-row slot="footer">
      <el-button type="danger" @click="$emit('close')">{{ $t("message.Annulla") | upperCase }}</el-button>
      <el-button type="success" @click="$emit('checkout-confirmed')"
        >{{ $t("message.Conferma") | upperCase }}</el-button
      >
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>