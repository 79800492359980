<template>
  <div id="main-action-bar">
    <ul class="action-list">
      <li v-if="isTablet || isMobile || isMobileOnly">
        <div
          class="action-wrapper"
          @click="mainMenuOpen ? emitCloseMenu() : emitOpenMenu()"
        >
          <Close v-if="mainMenuOpen && isTablet" class="action-icon"></Close>
          <Tune v-if="mainMenuOpen == false" class="action-icon"></Tune>
        </div>
      </li>
      <li v-if="mainMenuOpen == false && isMobileOnly || !isMobileOnly">
        <div
          class="action-wrapper"
          @click="
            resetConfiguration({
              forma: null,
              colore: null,
              coloreAste: null,
            });
            emitResetConfiguration();
          "
        >
          <!-- <Restore class="action-icon"></Restore> -->
          <div class="action-icon" style="z-index:100">
            <img alt="unco" src="../assets/images/ripristina.png" />
          </div>
          <span class="action-text">{{ $t('message.Ripristina') }}</span>
        </div>
      </li>
      <li>
        <div class="action-wrapper" @click="loginDialogVisible = true">
          <!-- <Account class="action-icon"></Account> -->
          <div class="action-icon" style="z-index:100">
            <img alt="unco" src="../assets/images/profilo.png" />
          </div>
          <span class="action-text">{{ $t('message.Profilo') }}</span>
        </div>
        <LoginDialog
          :visible="loginDialogVisible"
          @close="loginDialogVisible = false"
        />
      </li>
      <li v-if="!isGuest">
        <div class="action-wrapper" @click="cartDialogVisible = true">
          <el-badge :value="cartRows.length" type="primary">
            <!-- <Cart class="action-icon"></Cart> -->
            <div class="action-icon" style="z-index:100">
              <img alt="unco" src="../assets/images/carrello.png" />
            </div>
          </el-badge>
          <span class="action-text">{{ $t('message.Carrello') }}</span>
        </div>
        <CartDialog
          :visible="cartDialogVisible"
          @close="cartDialogVisible = false"
        />
      </li>
      <li v-if="!isGuest">
        <div class="action-wrapper" @click="historyDialogVisible = true">
          <Storico class="action-icon"></Storico>
          <span class="action-text">{{ $t('message.storico_ordini') | capitalize }}</span>
        </div>
        <HistoryDialog
          :visible="historyDialogVisible"
          @close="historyDialogVisible = false"
        />
      </li>
    </ul>
  </div>
</template>

<script>
  /* eslint-disable vue/no-unused-components */
  import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
  import { EventBus } from "../plugins/EventBus";

  import Account from "vue-material-design-icons/Account.vue";
  import Restore from "vue-material-design-icons/History.vue";
  import Cart from "vue-material-design-icons/Cart.vue";
  import Storico from "vue-material-design-icons/SortVariant.vue";
  import Close from "vue-material-design-icons/Close.vue";
  import Tune from "vue-material-design-icons/Tune.vue";

  import LoginDialog from "./main-action-bar/LoginDialog.vue";
  import CartDialog from "./main-action-bar/CartDialog.vue";
  import HistoryDialog from "./main-action-bar/HistoryDialog.vue";

  export default {
    components: {
      Account,
      Restore,
      Cart,
      Storico,
      Close,
      Tune,
      LoginDialog,
      CartDialog,
      HistoryDialog,
    },
    data() {
      return {
        loginDialogVisible: false,
        cartDialogVisible: false,
        historyDialogVisible: false,
        // isMobile: false,
        // isTablet: false,
        // isMobileOnly: false,
        // mainMenuOpen: true,
      };
    },
    async mounted() {
      let self = this;

      this.isMobile = EventBus.deviceIsMobile;
      this.isTablet = EventBus.deviceIsTablet;
      this.isMobileOnly = EventBus.deviceIsMobileOnly;

      await this.getUserData();
      EventBus.$on("open-cart", function () {
        self.cartDialogVisible = true;
      });
      EventBus.$on("open-history", function () {
        self.historyDialogVisible = true;
      });

      EventBus.$on("page-resize", (pr) => {
        this.isMobile = pr.isMobile;
        this.isTablet = pr.isTablet;
        this.isMobileOnly = pr.isMobileOnly;
      });
    },
    methods: {
      ...mapActions("user", ["getUserData"]),
      ...mapMutations(["resetConfiguration"]),
      emitCloseMenu() {
        EventBus.$emit("close-menu");
        this.mainMenuOpen = false;
      },
      emitOpenMenu() {
        EventBus.$emit("open-menu");
        this.mainMenuOpen = true;
      },
      emitResetConfiguration() {
        EventBus.$emit("reset-configuration");
      },
    },
    computed: {
      ...mapGetters("user", ["isGuest"]),
      ...mapState("cart", ["cartRows"]),
      // ...mapState(["isMobile", "isTablet", "isMobileOnly", "mainMenuOpen"]),
      isMobile: {
        get() {
          return this.$store.state.isMobile;
        },
        set(val) {
          this.$store.state.isMobile = val;
        },
      },
      isTablet: {
        get() {
          return this.$store.state.isTablet;
        },
        set(val) {
          this.$store.state.isTablet = val;
        },
      },
      isMobileOnly: {
        get() {
          return this.$store.state.isMobileOnly;
        },
        set(val) {
          this.$store.state.isMobileOnly = val;
        },
      },
      mainMenuOpen: {
        get() {
          return this.$store.state.mainMenuOpen;
        },
        set(val) {
          this.$store.state.mainMenuOpen = val;
        },
      },
    },
  };
</script>

<style lang="less" >
  #main-action-bar {
    position: absolute;
    top: 0;
    .action-list {
      padding-inline-start: 8px;
      display: flex;
      & > li {
        display: inline-block;
        margin: auto 10px;

        .action-wrapper {
          display: flex;
          flex-direction: column;
          cursor: pointer;

          .action-icon {
            svg {
              width: 40px;
              height: 40px;
              fill: @--color-primary;
              padding-right: 20px;
            }
          }
          .action-text {
            // padding-right: 20px;
          }
        }
      }
    }
  }

  @media @mobile {
    #main-action-bar {
      left: 0px;
      .action-list {
        width: 95vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
</style>