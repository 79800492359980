<template>
  <div id="left-menu">
    <div id="left-menu-body">
      <transition name="slide-left-out" mode="out-in">
        <ul v-if="activeMenu == null">
          <li
            v-for="item in menuNames"
            :key="item.id"
            class="menu-element"
            :class="{
              completed: isCompleted(item.name),
              hidden: item.name == 'WISHLIST' && !wishlistVisible,
            }"
            @click="activeMenu = item.name"
          >
            <span class="item-name">{{ $t(`${item.name }`) | upperCase }}</span>
            <i class="el-icon-arrow-right"></i>
          </li>
        </ul>
        <div v-else id="left-menu-active-body">
          <component
            v-if="activeComponent != null"
            :is="activeComponent"
            @close="activeMenu = null"
          ></component>
        </div>
      </transition>
    </div>

    <div id="left-menu-footer" :class="{ active: expandRecap }">
      <el-row class="recap-title-wrapper">
        <span class="recap-title">SUMMARY</span>
      </el-row>
      <el-row class="recap-data" :gutter="15" v-if="!expandRecap">
        <el-col :span="12" class="montatura">
          <span
            class="recap-data-title"
          >{{ $t('message.Montatura') | upperCase}}</span>
          <ul>
            <li>
              <span class="data-title">{{ $t('message.Modello') }}:</span>
              {{ frontale.U_THE_MODELLO }}
            </li>
            <li>
              <span class="data-title">{{ $t('message.Misura') }}:</span>
              {{ frontSize }}
            </li>
            <li>
              <span class="data-title">Asian Fitting:</span>
              {{ asianFitting | af }}
            </li>
            <li>
              <span class="data-title">{{ $t('message.Colore') }}:</span>
              <span
                v-if="colore"
              >{{ colore.U_THE_COLORE.replaceAll('PL','').replaceAll('EP','') }}</span>
            </li>
          </ul>
        </el-col>
        <el-col :span="12" class="aste">
          <span class="recap-data-title">{{ $t('message.Aste') | upperCase}}</span>
          <ul>
            <!-- <li>
              <span class="data-title">{{ $t('message.Materiale') }}:</span>
              {{ templesMaterial | materialLabel }}
            </li>-->
            <li>
              <span class="data-title">{{ $t('message.Misura') }}:</span>
              {{ misuraAste }}
            </li>
            <li>
              <span class="data-title">{{ $t('message.Colore') }}:</span>
              <span
                v-if="coloreAste"
              >{{ coloreAste.U_THE_COLORE.replaceAll('PL','') }}</span>
            </li>
          </ul>
        </el-col>
      </el-row>
      <el-row class="recap-data" :gutter="15" v-else>
        <el-col :span="18" class="full-recap">
          <ul>
            <li>
              <span class="data-title">{{ $t('message.Modello') }}:</span>
              {{ frontale.U_THE_MODELLO }}
            </li>
            <li>
              <span class="data-title">{{ $t('message.Misura') }}:</span>
              {{ frontSize }}
            </li>
            <li>
              <span class="data-title">Asian Fitting:</span>
              {{ asianFitting | af }}
            </li>
            <li>
              <span
                class="data-title"
              >{{ $t('message.frontale') | capitalize}} {{ $t('message.Colore') }}:</span>
              {{ colore.U_THE_COLORE.replaceAll('PL','').replaceAll('EP','') }}
            </li>
            <li>
              <span
                class="data-title"
              >{{ $t('message.frontale') | capitalize}} {{ $t('message.Finitura') }}:</span>
              {{ finitura | finishingLabel | finishingLabelEN}}
            </li>
            <!-- <li>
              <span class="data-title">{{ $t('message.Materiale') }}:</span>
              {{ templesMaterial | materialLabel }}
            </li>-->
            <li>
              <span class="data-title">{{ $t('message.Misura') }}:</span>
              {{ misuraAste }}
            </li>
            <li>
              <span
                class="data-title"
              >{{ $t('message.Aste') }} {{ $t('message.Colore') }}:</span>
              {{ coloreAste.U_THE_COLORE.replaceAll('PL','') }}
            </li>
            <li>
              <span
                class="data-title"
              >{{ $t('message.Aste') }} {{ $t('message.Finitura') }}:</span>
              {{ finituraAste | finishingLabel | finishingLabelEN}}
            </li>
            <li>
              <span class="data-title">{{ $t('message.Incisione') }}:</span>
              {{ nome }}
            </li>
            <li>
              <span
                class="data-title"
              >{{ $t('message.Incisione') }} {{ $t('message.Colore') }}:</span>
              {{ coloreNome }}
            </li>
            <li>
              <span class="data-title">{{ $t('message.Note') }}:</span>
              <Edit
                class="edit-note"
                v-if="!editNote"
                @click="editNote = !editNote"
              />
              <br />
              <span v-if="!editNote">{{ note }}</span>
              <div v-else class="edit-note-action-wrapper">
                <el-input
                  type="textarea"
                  v-model="rowNote"
                  @keydown.enter.exact.prevent
                  @keyup.enter.exact="editNote = false"
                ></el-input>
                <el-button
                  size="mini"
                  class="pull-right"
                  @click="editNote = !editNote"
                >
                  <Check class="edit-note" />
                </el-button>
              </div>
            </li>
          </ul>
        </el-col>
      </el-row>
      <el-row :gutter="15" class="recap-data-actions">
        <el-col v-if="expandRecap" :span="22" :offset="1">
          <!-- <h4 class="text-center">CLICCA AGGIUNGI PER AGGIUNGERE AL CARRELLO</h4>

          <small class="text-extra-small">
            Avviso: una volta aggiunto il prodotto personalizzato al carrello
            non sarà possibile modificarlo.
            Comunque sarà sempre possibile rimuovere il prodotto personalizzato
            dal carrello e crearne uno nuovo.
            Il reso dei prodotti personalizzati è possibile solo per difetti di
            fabbricazione
          </small>-->
        </el-col>
        <el-col :span="12">
          <el-button
            size="mini"
            class="action-button"
            :disabled="!configurationComplete"
            @click="saveConfiguration"
          >
            <Favorite :size="18" class="button-icon" />
            <span>{{ $t('message.SALVA')}}</span>
          </el-button>
        </el-col>
        <el-col :span="12">
          <div v-if="expandRecap == false">
            <el-button
              size="mini"
              :disabled="!canAddToCart"
              class="action-button"
              @click="expandRecap = true"
            >
              <Cart :size="18" class="button-icon" />
              <span>{{ $t('message.Aggiungi') | upperCase}}</span>
            </el-button>
          </div>
          <div v-else>
            <el-button
              id="add-button"
              size="mini"
              :disabled="!canAddToCart"
              class="action-button"
              @click="addToUserCart()"
            >
              <Cart :size="18" class="button-icon" />
              <span>{{ $t('message.Aggiungi') | upperCase}}</span>
            </el-button>
          </div>
        </el-col>
        <el-col class="recap-body-action" :span="24">
          <BodyActions
            v-if="expandRecap"
            @close="expandRecap = false"
            :text="$t('message.INDIETRO')"
          />
        </el-col>
      </el-row>
    </div>
    <AddToCartDialog
      :isVisible="addToCartDialogVisible"
      @close="addToCartDialogVisible = false"
    />
    <ErrorAddToCartDialog
      :isVisible="errorAddToCartDialogVisible"
      @close="errorAddToCartDialogVisible = false"
    />
  </div>
</template>

<script>
  import jslinq from "jslinq";
  import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
  import { EventBus } from "../plugins/EventBus";

  import Components from "./left-menu";

  export default {
    components: Components,
    data() {
      return {
        activeMenu: null,
        expandRecap: false,
        editNote: false,
        addToCartDialogVisible: false,
        errorAddToCartDialogVisible: false,
        visible: true,
        menuNames: [
          {
            id: 1,
            name: "message.Montatura",
            component: Components.Montature,
            visible: true,
          },
          {
            id: 2,
            name: "message.Colore",
            component: Components.Colori,
            visible: true,
          },
          {
            id: 3,
            name: "message.Aste",
            component: Components.Aste,
            visible: true,
          },
          {
            id: 4,
            name: "message.Incisione",
            component: Components.Personalizzazioni,
            visible: true,
          },
          {
            id: 5,
            name: "WISHLIST",
            component: Components.Wishlist,
            visible: this.wishlistVisible,
          },
        ],
      };
    },
    async mounted() {
      await this.getItems(false);
      await this.getColors();
      await this.getTemplesColors();

      // const c = jslinq(this.colors),
      //   black = c.firstOrDefault((col) => {
      //     return col.U_THE_COLORE == "PL0001";
      //   }),
      //   ca = jslinq(this.coloriAste),
      //   blackAste = ca.firstOrDefault((col) => {
      //     return col.U_THE_COLORE == "PL0001" && col.U_THE_LUNGASTA == 145;
      //   });
      // // self = this,
      // this.setColore(black);
      // this.setTemplesColor(blackAste);
    },
    methods: {
      ...mapActions("frontali", ["getItems", "getColors"]),
      ...mapActions("aste", { getTemplesColors: "getAllColors" }),
      ...mapActions("cart", ["addToCart"]),
      ...mapMutations([
        "saveConfiguration",
        "setRowNotes",
        "setColore",
        "setTemplesColor",
        "resetConfiguration",
      ]),
      isCompleted(menuName) {
        switch (menuName) {
          case "message.Montatura":
            return this.modelErrors && this.modelErrors.length == 0;

          case "message.Colore":
            return this.colorErrors && this.colorErrors.length == 0;

          case "message.Aste":
            return this.templesErrors && this.templesErrors.length == 0;

          case "message.Incisione":
            return this.nome != "";
        }
      },
      async addToUserCart() {
        let response = await this.addToCart();
        console.log(response);
        if (
          response.status >= 200 &&
          response.status < 300 &&
          response.data.status.success
        ) {
          this.addToCartDialogVisible = true;
          this.expandRecap = false;
          EventBus.$emit("reset-configuration");
          this.resetConfiguration({
            forma: null,
            colore: null,
            coloreAste: null,
          });
        } else {
          this.errorAddToCartDialogVisible = true;
        }
      },
    },
    computed: {
      ...mapState([
        "frontale",
        "colore",
        "finitura",
        "misuraAste",
        "coloreAste",
        "finituraAste",
        "configurations",
        "nome",
        "coloreNome",
        "note",
        "asianFitting",
      ]),
      ...mapState("frontali", ["colors"]),
      ...mapState("aste", { coloriAste: "allColors" }),
      ...mapGetters("frontali", ["checkErrors"]),
      ...mapGetters(["frontSize", "templesMaterial"]),
      ...mapGetters("aste", { templesErrors: "checkErrors" }),
      ...mapGetters("cart", ["canAddToCart"]),
      ...mapGetters(["configurationComplete"]),
      activeComponent() {
        if (this.activeMenu != null) {
          let mn = jslinq(this.menuNames),
            am = this.activeMenu;
          let res = mn.firstOrDefault((e) => {
            return e.name == am;
          });

          return res.component;
        }

        return null;
      },
      rowNote: {
        get() {
          return this.note;
        },
        set(n) {
          this.setRowNotes(n);
        },
      },
      modelErrors() {
        let e = jslinq(this.checkErrors);
        return e
          .where((error) => {
            return error.label == "model" || error.label == "size";
          })
          .toList();
      },
      colorErrors() {
        let e = jslinq(this.checkErrors);
        return e
          .where((error) => {
            return error.label == "color";
          })
          .toList();
      },
      wishlistVisible() {
        return this.configurations && this.configurations.length > 0;
      },
    },
    watch: {
      activeMenu(newVal, oldVal) {
        if (oldVal === "message.Incisione") {
          // eslint-disable-next-line no-undef
          configuratorEventBus.$emit("cfg-personalizzazione", {
            nome: this.nome,
            coloreNome: this.coloreNome,
          });
        }
      },
    },
  };
</script>

<style lang="less" >
  #left-menu {
    height: 100%;
    position: relative;
    #left-menu-footer,
    #left-menu-body {
      min-width: 100%;
    }

    #left-menu-body {
      height: 75%;
      position: absolute;
      background-color: @--border-color-base;
      // max-width: @--menu-width;
      // min-width: @--menu-width;
      width: @--menu-width;
      #left-menu-active-body {
        height: 100%;
        & > div {
          height: 100%;
        }
      }
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li.menu-element {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 15px;
          border-top: solid @--border-color-base 1px;
          border-bottom: solid @--border-color-base 1px;
          background-color: @--border-color-light;
          cursor: pointer;

          &.active,
          &.completed {
            // classe usata nei componenti di left-menu
            background-color: @--color-black;
            color: @--color-white;
          }
        }
      }

      .body-elements {
        min-height: ~"calc(100% - 104px)"; // altezza totale - body-titel - body-actions
        max-height: ~"calc(100% - 104px)"; // altezza totale - body-titel - body-actions
        height: ~"calc(100% - 104px)"; // altezza totale - body-titel - body-actions
      }
      .body-actions {
        position: absolute;
        min-width: 100%;
      }
    }

    .slide-left-out-enter-active {
      transition: all 0.3s ease;
    }
    .slide-left-out-leave-active {
      transition: all 0.3s;
    }
    .slide-left-out-enter,
    .slide-left-out-leave-to {
      transform: translateX(-360px);
      opacity: 0;
    }
    #left-menu-footer {
      display: flex;
      flex-direction: column;
      align-content: center;
      min-width: 100%;
      max-width: 360px;
      height: 25%;
      background-color: @--color-primary;
      color: @--color-white;
      padding: 8px;
      box-sizing: border-box;
      text-align: left;
      position: relative;
      top: 75%;
      justify-content: center;
      transition-duration: 0.5s;

      &.active {
        position: absolute;
        height: 100vh;
        top: 0;
        justify-content: space-between;

        .full-recap {
          li {
            margin-top: 10px;

            .edit-note {
              margin-left: 10px;
              cursor: pointer;
              svg {
                width: 15px;
                height: 15px;
              }
            }
          }

          .edit-note-action-wrapper {
            .el-button--mini {
              float: right;
              margin-top: 3px;
              padding: 3px 15px !important;
              svg {
                width: 15px;
                height: 15px;
              }
            }
          }
        }

        .recap-body-action {
          height: 52px;
          padding: 0 !important;
          margin-bottom: 0 !important;
        }
        .recap-data-actions > .el-col {
          margin-bottom: 15px;
        }
      }

      .recap-title-wrapper {
        font-weight: bold;
        text-align: center;
        left: -8px;
      }
      .recap-data {
        margin-top: 8px;

        .data-title {
          font-weight: 400;
        }

        .recap-data-title {
          padding: 8px;
          font-weight: 100;
        }

        ul {
          list-style-type: none;
          text-align: left;
          padding-left: 8px;
          font-weight: 100;
        }
      }
      .recap-data-actions {
        .action-button {
          width: 100%;
          & > span {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .button-icon {
            padding: 0 5px;
          }
        }
      }
    }
  }

  @media @mobile {
    #left-menu {
      height: calc(100% - 40px);
      #left-menu-body {
        width: @--menu-width-mobile !important;
      }
    }
  }
</style>