<template>
  <el-dialog
    id="detail-order-dialog"
    :visible="visible"
    @close="$emit('close')"
    append-to-body
    width="85%"
    class="text-center"
  >
    <span slot="title" class="dialog-title">
      {{ $t('message.Ordine') }} #{{ order.ID }} {{ order.date | pretyDate }}
    </span>
    <el-row class="table-row">
      <el-table :data="order.details" border>
        <el-table-column
          width="150"
          prop="ID_RIGA"
          label="ID"
        ></el-table-column>
        <el-table-column
          width="150"
          prop="row_state"
          :label="$t('message.Status')"
        ></el-table-column>
        <el-table-column
          width="150"
          prop="COD_PROD"
          :label="$t('message.frontale') | capitalize"
        ></el-table-column>
        <el-table-column
          width="150"
          prop="COLORE"
          :label="$t('message.Colore_frontale')"
        ></el-table-column>
        <el-table-column width="150" prop="Finishing" :label="$t('message.Finitura')">
          <template slot-scope="scope">
            {{ scope.row.Finishing | finishingLabel }}
          </template>
        </el-table-column>
        <el-table-column
          width="150"
          prop="FinishingTemple"
          :label="$t('message.Finitura') + $t('message.aste')"
        >
          <template slot-scope="scope">
            {{ scope.row.FinishingTemple | finishingLabel }}
          </template>
        </el-table-column>
        <el-table-column width="150" :label="$t('message.Misura')">
          <template slot-scope="scope">
            {{ scope.row.size_eye }}/{{ scope.row.size_bridge }}
          </template>
        </el-table-column>
        <el-table-column
          width="150"
          prop="COD_ASTE"
          :label="$t('message.aste') | capitalize"
        ></el-table-column>
        <el-table-column
          width="150"
          prop="nome"
          :label="$t('message.Nome_personalizzato')"
        ></el-table-column>
        <el-table-column
          width="150"
          prop="colore_nome"
          :label="$t('message.Colore')"
        ></el-table-column>
        <el-table-column
          width="150"
          prop="Note"
          :label="$t('message.Riferimento')"
        ></el-table-column>
      </el-table>
    </el-row>
  </el-dialog>
</template>

<script>
/** Esempio prop order
 * 
 {
  "ID": "97699",
  "date": "2021-10-05 07:31:59",
  "username": "C0000000010",
  "contactemployes": "C000",
  "Notes": "",
  "status": [
    {
      "tot": "1",
      "row_state": "NEW",
      "id": "97699"
    }
  ],
  "details": [
    {
      "ID": "97699",
      "ID_RIGA": "187077",
      "COD_PROD": "DEMENEGO CUSTOM MOD. DC-001",
      "U_THE_MODELLO": "DC-001",
      "Qnt": "1",
      "Note": "",
      "COD_ASTE": "DEMENEGO ASTE 145MM C.0001",
      "TemplesColorCode": "PL0001",
      "TemplesSize": "145",
      "Finishing": "S",
      "AsianFitting": "0",
      "row_state": "NEW",
      "size_eye": "52",
      "size_bridge": "16",
      "COLORE": "PL0313",
      "colore_nome": "",
      "font_nome": "",
      "nome": "",
      "linea": "DEMENEGO",
      "site_id": "DEMENEGO",
      "FinishingTemple": "S",
      "INT_ASTA_SX_COLORE_NOME": null,
      "INT_ASTA_SX_FONT_NOME": null,
      "INT_ASTA_SX_NOME": null,
      "EST_ASTA_SX_COLORE_NOME": null,
      "EST_ASTA_SX_FONT_NOME": null,
      "EST_ASTA_SX_NOME": null,
      "INT_ASTA_DX_COLORE_NOME": null,
      "INT_ASTA_DX_FONT_NOME": null,
      "INT_ASTA_DX_NOME": null,
      "EST_ASTA_DX_COLORE_NOME": null,
      "EST_ASTA_DX_FONT_NOME": null,
      "EST_ASTA_DX_NOME": null
    }
  ]
}
*/

import moment from "moment";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
    },
  },
  filters: {
    pretyDate(d) {
      let md = moment(d, "YYYY-MM-DD hh:mm:ss");
      return md.format("DD/MM/YYYY");
    },
  },
};
</script>

<style>
</style>