/* eslint-disable no-unused-vars */
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import Element from "element-ui";
import "./theme/index.css";
import "./theme/de-menego.less";
import locale from "element-ui/lib/locale/lang/en";
import VueCookie from "vue-cookies";
import ajax from './plugins/ajax_request'
import crypto from "crypto"
import VueI18n from 'vue-i18n'
// // eslint-disable-next-line no-unused-vars
// import lang from 'element-ui/lib/locale/lang/en'

Vue.config.productionTip = false
Vue.use(Element, { locale })
Vue.use(VueCookie)
Vue.use(VueI18n);



Vue.filter('colorImage', c => {
  return `${process.env.VUE_APP_COLORS_URL}frontali/${c}.jpg`;
})
Vue.filter('finishingLabel', f => {
  return f == "S" ? "Lucido" : "Opaco";
})
Vue.filter('finishingLabelEN', f => {
  return f == "Lucido" ? "Shiny" : "Matt";
})
Vue.filter('materialLabel', m => {
  return m == "PL" ? "Plastica" : "Metallo";
})

Vue.filter('modelImage', m => {
  return `${process.env.VUE_APP_MODEL_URL + m}.png`;
})

Vue.filter('af', f => {
  return f == false ? "NO" : "YES";
})

Vue.filter('upperCase', (s) => {
  if (typeof s !== 'string') return ''
  return s.toUpperCase()
})

Vue.filter('capitalize', (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
})

window.configuratorEventBus = new Vue();

// locale.use(lang)

const i18n = new VueI18n({
  locale: 'EN',
  //messages,
  fallbackLocale: 'EN'
})

ajax.getCountryCode().then((response) => {
  // i18n.locale = response.data;
  i18n.locale = 'US';
});

ajax.getTranslations().then((response) => {
  let messages = response.data;
  for (let key in messages) {
    i18n.setLocaleMessage(key, messages[key]);
  }
});

//  TODO: eliminare variabili relative a sendgrid nei file ambiente
new Vue({
  i18n,
  store,
  render: h => h(App),
  // created() {
  //   ajax.setAuthHeader();
  //   Vue.$cookies.set("_identity", process.env.VUE_APP_AUTENTICATION_HASH, "30d");
  //   this.$store.dispatch('user/getUserData')
  //   this.$store.commit('user/setSessionId', localStorage.getItem('cfg-session-id'));
  //   if (!this.$store.state.user.sessionId) {
  //     let c = crypto.randomBytes(16).toString('base64');

  //     this.$store.commit('user/setSessionId', c);
  //     localStorage.setItem('cfg-session-id', this.$store.state.user.sessionId);
  //   }
  // }
}).$mount('#cfg-unco-usa')
