<template>
  <div id="colori">
    <ul class="body-title">
      <li class="menu-element active" @click="$emit('close')">
        <span>{{ $t('message.Colore') | upperCase}}</span>
        <i class="el-icon-arrow-right"></i>
      </li>
    </ul>
    <div class="body-elements">
      <el-row id="filter-row">
        <el-col :span="24">
          <span class="label">{{ $t('message.Finitura') | upperCase}}</span>
          <el-radio-group v-model="finishing">
            <el-radio
              v-for="f in finiture"
              :key="f"
              :label="f | finishingLabel | finishingLabelEN"
              name="finitura"
            ></el-radio>
          </el-radio-group>
        </el-col>
        <el-col :span="24">
          <el-row>
            <el-col class="model-filter-column" :span="20">
              <span class="label">{{ $t('message.Cerca') | upperCase}}</span>
              <el-input
                v-model="filter"
                size="small"
                clearable
                :placeholder="$t('message.Cerca')+` (${colors.length} colors)`"
              ></el-input>
            </el-col>
            <el-col class="icon-column" :span="4">
              <Search />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row id="colors-preview-row">
        <el-col
          class="color-preview-column"
          :class="{ active: color.ItemCode == colore.ItemCode }"
          v-for="color in filteredColors"
          :key="color.ItemCode"
          :span="8"
        >
          <div v-if="color.ItemCode == colore.ItemCode" class="active-flag">
            <img src="../../assets/model-active.png" alt="active" />
          </div>
          <div class="color-preview-image" @click="setColore(color)">
            <img :src="color.U_THE_COLORE | colorImage" alt />
          </div>
          <div class="color-preview-name">
            <span>{{ color.U_THE_COLORE.replaceAll('PL','').replaceAll('EP','') }}</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="body-actions">
      <body-actions @close="$emit('close')" />
    </div>
  </div>
</template>

<script>
  import jslinq from "jslinq";
  import { mapState, mapActions, mapMutations } from "vuex";

  import Search from "vue-material-design-icons/Magnify.vue";

  import BodyActions from "./BodyActions.vue";
  export default {
    components: {
      BodyActions,
      Search,
    },
    data() {
      return {
        finiture: ["S", "M"],
        materiali: [],
        filter: "",
      };
    },
    async mounted() {
      await this.getColors();
    },
    methods: {
      ...mapActions("frontali", ["getColors"]),
      ...mapMutations(["setColore", "setFinishing"]),
    },
    computed: {
      ...mapState(["finitura", "colore"]),
      ...mapState("frontali", ["colors"]),
      filteredColors() {
        let c = jslinq(this.colors);
        if (this.filter != "") {
          return c
            .where((color) => {
              return color.U_THE_COLORE.indexOf(this.filter) >= 0;
            })
            .orderBy((color) => {
              color.Order;
            })
            .toList();
        }
        return c
          .orderBy((color) => {
            return color.Order;
          })
          .toList();
      },
      finishing: {
        get() {
          return this.finitura == "S" ? "Shiny" : "Matt";
        },
        set(f) {
          let fin = f == "Shiny" ? "S" : "M";
          this.setFinishing(fin);
        },
      },
    },
  };
</script>

<style lang="less">
  #colors-preview-row {
    height: ~"calc(100% - 30px*3 - 10px*2)"; // 100% - 30 * (numero righe + 30 di padding) + margini * numero righe
  }
  .color-preview-column {
    margin-top: auto !important;
    height: auto !important;
    img {
      max-width: 100%;
    }
    .color-preview-image {
      cursor: pointer;
    }
  }
</style>