<template>
  <div id="aste">
    <ul class="body-title">
      <li class="menu-element active" @click="$emit('close')">
        <span>{{ $t('message.Aste') | upperCase}}</span>
        <i class="el-icon-arrow-right"></i>
      </li>
    </ul>
    <div class="body-elements">
      <el-row id="filter-row">
        <el-col :span="24">
          <span class="label">{{ $t('message.Misura') | upperCase}}</span>
          <el-radio-group v-model="size">
            <el-radio v-for="s in sizes" :key="s" :label="s" name="size"></el-radio>
          </el-radio-group>
        </el-col>
        <el-col :span="24">
          <span class="label">{{ $t('message.Finitura') | upperCase}}</span>
          <el-radio-group v-model="finishing">
            <el-radio
              v-for="f in finiture"
              :key="f"
              :label="f | finishingLabel | finishingLabelEN"
              name="finishing"
            ></el-radio>
          </el-radio-group>
        </el-col>
        <!-- <el-col :span="24">
          <span class="label"> MATERIALE </span>
          <el-radio-group v-model="material">
            <el-radio
              v-for="m in materials"
              :key="m"
              :label="m | materialLabel"
              name="material"
            ></el-radio>
          </el-radio-group>
        </el-col>-->
        <el-col :span="24">
          <el-row>
            <el-col class="model-filter-column" :span="20">
              <span class="label">{{ $t('message.Cerca') | upperCase}}</span>
              <el-input
                v-model="filter"
                size="small"
                clearable
                :placeholder="$t('message.Cerca')+` (${filteredColors.length} colors)`"
              ></el-input>
            </el-col>
            <el-col class="icon-column" :span="4">
              <Search />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row id="temples-preview-row">
        <el-col
          class="color-preview-column"
          :class="{ active: color.ItemCode == coloreAste.ItemCode }"
          v-for="color in filteredColors"
          :key="color.ItemCode"
          :span="8"
        >
          <div v-if="color.ItemCode == coloreAste.ItemCode" class="active-flag">
            <img src="../../assets/model-active.png" alt="active" />
          </div>
          <div class="color-preview-image" @click="setTemplesColor(color)">
            <img :src="color.U_THE_COLORE | colorImage" alt />
          </div>
          <div class="color-preview-name">
            <span>{{ color.U_THE_COLORE.replaceAll('PL','') }}</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="body-actions">
      <body-actions @close="$emit('close')" />
    </div>
  </div>
</template>

<script>
  import jslinq from "jslinq";
  import { mapActions, mapMutations, mapState } from "vuex";

  import BodyActions from "./BodyActions.vue";
  import Search from "vue-material-design-icons/Magnify.vue";

  export default {
    components: {
      BodyActions,
      Search,
    },
    data() {
      return {
        finiture: ["S", "M"],
        filter: "",
        materials: ["PL", "MT"],
        // size: "",
      };
    },
    async mounted() {
      if (this.colors.length == 0) this.getAllColors();
    },
    methods: {
      ...mapActions("aste", ["getAllColors"]),
      ...mapMutations([
        "setTemplesFinishing",
        "setTemplesMaterial",
        "setTemplesSize",
        "setTemplesColor",
      ]),
    },
    computed: {
      ...mapState("aste", {
        colors: "allColors",
      }),
      ...mapState([
        "finituraAste",
        "templesMaterial",
        "misuraAste",
        "coloreAste",
      ]),
      finishing: {
        get() {
          return this.finituraAste == "S" ? "Shiny" : "Matt";
        },
        set(f) {
          let fin = f == "Shiny" ? "S" : "M";
          this.setTemplesFinishing(fin);
        },
      },
      material: {
        get() {
          return this.templesMaterial == "PL" ? "Plastica" : "Metallo";
        },
        set(m) {
          let mat = m == "Plastica" ? "PL" : "MT";
          this.setTemplesMaterial(mat);
        },
      },
      sizes: {
        get() {
          let c = jslinq(this.colors);
          return c
            .select((col) => {
              return col.U_THE_LUNGASTA;
            })
            .distinct()
            .toList();
        },
        set(s) {
          this.size = s;
        },
      },
      size: {
        get() {
          return this.misuraAste;
        },
        set(m) {
          this.setTemplesSize(m);
        },
      },
      filteredColors() {
        let c = jslinq(this.colors),
          f = this.filter;

        return c
          .where((col) => {
            let valid = true;
            if (f != "") valid = valid && col.U_THE_COLORE.indexOf(f) >= 0;

            return valid && col.U_THE_LUNGASTA == this.size;
          })
          .orderBy((col) => {
            return col.Order;
          })
          .toList();
      },
    },
    watch: {
      size(newVal) {
        if (this.coloreAste != "" && this.coloreAste.U_THE_LUNGASTA != newVal) {
          let items = jslinq(this.colors),
            target = items.firstOrDefault((c) => {
              return (
                c.U_THE_LUNGASTA == newVal &&
                c.U_THE_COLORE == this.coloreAste.U_THE_COLORE
              );
            });

          this.setTemplesColor(target);
          console.log(target);
          console.log(newVal);
        }
      },
      coloreAste(newVal) {
        if (this.size != newVal.U_THE_LUNGASTA) this.size = newVal.U_THE_LUNGASTA;
      },
    },
  };
</script>

<style lang="less" >
  #temples-preview-row {
    height: ~"calc(100% - 30px*4 - 10px*3)"; // 100% - 30 * (numero righe + 30 di padding) + margini * numero righe
  }
</style>