<template>
  <el-dialog
    id="checkout-dialog"
    append-to-body
    :visible="visible"
    @close="$emit('close')"
    width="600px"
  >
    <span>{{ $t('message.Il_tuo_ordine_è_andato_a_buon_fine') | upperCase }}</span>
    <el-row slot="footer">
      <el-button type="primary" @click="$emit('close-cart')"
        >{{ $t('message.Ritorna_al_configuratore') | upperCase }}</el-button
      >
      <el-button type="primary" @click="openHistory"
        >{{ $t('message.Vai_allo_storico_ordini') | upperCase }}</el-button
      >
    </el-row>
  </el-dialog>
</template>

<script>
import { EventBus } from "../../../plugins/EventBus";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openHistory() {
      EventBus.$emit("open-history");
      this.$emit("close");
    },
  },
};
</script>

<style>
</style>