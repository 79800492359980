var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"personalizzazioni"}},[_c('ul',{staticClass:"body-title"},[_c('li',{staticClass:"menu-element active",on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',[_vm._v(_vm._s(_vm._f("upperCase")(_vm.$t('message.Incisione'))))]),_c('i',{staticClass:"el-icon-arrow-right"})])]),_c('div',{staticClass:"body-elements"},[_c('el-row',{attrs:{"id":"personalizations-preview-row","gutter":16}},[_c('el-col',{staticClass:"preview-column",attrs:{"span":20,"offset":2}},[_c('personal-name-preview',{attrs:{"name":_vm.pName,"colore":_vm.activeColor}})],1),_c('el-col',{staticClass:"name-preview-column",attrs:{"span":18,"offset":3}},[_c('el-input',{attrs:{"size":"small","maxlength":10,"placeholder":_vm.$t('message.Max_10_caratteri!')},model:{value:(_vm.pName),callback:function ($$v) {_vm.pName=$$v},expression:"pName"}})],1),_c('div',{staticClass:"el-col",attrs:{"span":20,"offset":2}},[_vm._v(_vm._s(_vm._f("upperCase")(_vm.$t('message.Scegli_il_colore'))))]),_vm._l((_vm.colors),function(color){return _c('el-col',{key:color.colorCode,staticClass:"color-name-preview",class:{
          active: _vm.activeColor && _vm.activeColor.colorClass == color.colorClass,
        },attrs:{"span":8}},[_c('div',{staticClass:"name-color",class:{
            oro: color.name == 'oro',
            argento: color.name == 'argento',
            bordered: color.name == '',
          },style:({ backgroundColor: color.colorCode }),on:{"click":function($event){_vm.activeColor = color}}}),(_vm.activeColor && _vm.activeColor.colorClass == color.colorClass)?_c('div',{staticClass:"active-flag"},[_c('img',{attrs:{"src":require("../../assets/model-active.png"),"alt":"active"}})]):_vm._e()])})],2)],1),_c('div',{staticClass:"body-actions"},[_c('body-actions',{on:{"close":function($event){return _vm.$emit('close')}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }