<template>
  <div id="personalizzazioni">
    <ul class="body-title">
      <li class="menu-element active" @click="$emit('close')">
        <span>{{ $t('message.Incisione') | upperCase}}</span>
        <i class="el-icon-arrow-right"></i>
      </li>
    </ul>
    <div class="body-elements">
      <el-row id="personalizations-preview-row" :gutter="16">
        <el-col :span="20" :offset="2" class="preview-column">
          <personal-name-preview :name="pName" :colore="activeColor" />
        </el-col>
        <el-col :span="18" :offset="3" class="name-preview-column">
          <el-input
            v-model="pName"
            size="small"
            :maxlength="10"
            :placeholder="$t('message.Max_10_caratteri!')"
          ></el-input>
        </el-col>
        <div
          class="el-col"
          :span="20"
          :offset="2"
        >{{ $t('message.Scegli_il_colore') | upperCase}}</div>
        <el-col
          v-for="color in colors"
          :key="color.colorCode"
          :span="8"
          class="color-name-preview"
          :class="{
            active: activeColor && activeColor.colorClass == color.colorClass,
          }"
        >
          <div
            class="name-color"
            :style="{ backgroundColor: color.colorCode }"
            :class="{
              oro: color.name == 'oro',
              argento: color.name == 'argento',
              bordered: color.name == '',
            }"
            @click="activeColor = color"
          ></div>
          <div
            v-if="activeColor && activeColor.colorClass == color.colorClass"
            class="active-flag"
          >
            <img src="../../assets/model-active.png" alt="active" />
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="body-actions">
      <body-actions @close="$emit('close')" />
    </div>
  </div>
</template>

<script>
  import jslinq from "jslinq";
  import { mapMutations, mapState } from "vuex";
  import BodyActions from "./BodyActions.vue";
  import PersonalNamePreview from "./PersonalNamePreview_IntAstDx.vue";
  export default {
    components: {
      BodyActions,
      PersonalNamePreview,
    },
    data() {
      return {
        // activeColor: {},
        colors: [
          {
            name: "nero",
            colorCode: "#212121",
            colorClass: "grey darken-4",
            checkColor: "white",
          },
          {
            name: "bianco",
            colorCode: "#fafafa",
            colorClass: "grey lighten-5",
            checkColor: "grey",
          },
          {
            name: "verde",
            colorCode: "#4caf50",
            colorClass: "green",
            checkColor: "white",
          },
          {
            name: "blu",
            colorCode: "#2196f3",
            colorClass: "blu",
            checkColor: "white",
          },
          {
            name: "giallo",
            colorCode: "#FFEB3B",
            colorClass: "yellow",
            checkColor: "grey",
          },
          {
            name: "rosso",
            colorCode: "#f44336",
            colorClass: "red",
            checkColor: "white",
          },
          {
            name: "rosa",
            colorCode: "#F8BBD0",
            colorClass: "pink",
            checkColor: "white",
          },
          {
            name: "arancione",
            colorCode: "#FF9800",
            colorClass: "orange",
            checkColor: "white",
          },
          {
            name: "viola",
            colorCode: "#AB47BC",
            colorClass: "purple",
            checkColor: "white",
          },
          {
            name: "oro",
            colorCode: "#FFD700",
            colorClass: "gold",
            checkColor: "white",
          },
          {
            name: "argento",
            colorCode: "#D3D3D3",
            colorClass: "silver",
            checkColor: "white",
          },
          {
            name: "azzurro",
            colorCode: "#81D4FA",
            colorClass: "light blue",
            checkColor: "white",
          },
          {
            name: "",
            colorCode: "",
            colorClass: "no color",
            checkColor: "",
          },
        ],
      };
    },
    methods: {
      ...mapMutations(["setName", "setColoreNome"]),
    },
    computed: {
      ...mapState(["nome", "coloreNome"]),
      pName: {
        get() {
          return this.nome.replace('"', "");
        },
        set(n) {
          this.setName(n);
        },
      },
      activeColor: {
        get() {
          let c = jslinq(this.colors);
          return c.firstOrDefault((col) => {
            return col.name == this.coloreNome;
          });
        },
        set(c) {
          this.setColoreNome(c.name);
        },
      },
    },
    // watch: {
    //   activeColor: {
    //     deep: true,
    //     handler: function (newVal) {
    //       this.coloreNome == newVal.name;
    //     },
    //   },
    // },
  };
</script>

<style lang="less" >
  #personalizations-preview-row {
    // overflow-y: auto !important;
    height: 100%;
    padding: 16px;
    .name-preview-column {
      margin-top: 8px;
      margin-bottom: 8px;
    }
    .color-name-preview {
      height: 50px;
      margin-top: 8px;
      margin-bottom: 8px;
      position: relative;
      .name-color {
        // border: solid green 1px;
        height: 100%;
        border-radius: 3px;
        &.bordered {
          background-image: url("../../assets/no-color.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        &.oro {
          background: transparent
            linear-gradient(
              180deg,
              #eebb6b 1%,
              #f3cc78 19%,
              #f4eade 41%,
              #bb700e 73%,
              #e8b269 93%,
              #ecb871 95%
            )
            0% 0% no-repeat padding-box;
        }

        &.argento {
          background: transparent
            linear-gradient(
              180deg,
              #757474 1%,
              #858585 6%,
              #b1b1b1 17%,
              var(--unnamed-color-bababa) 19%,
              #f4eade 41%,
              #2e2e2d 73%,
              #5d5c5c 95%
            )
            0% 0% no-repeat padding-box;
          background: transparent
            linear-gradient(
              180deg,
              #757474 1%,
              #858585 6%,
              #b1b1b1 17%,
              #bababa 19%,
              #f4eade 41%,
              #2e2e2d 73%,
              #5d5c5c 95%
            )
            0% 0% no-repeat padding-box;
        }
      }

      &.active {
        .active-flag {
          position: absolute;
          right: 10px;
          top: 3px;
        }
      }
    }
  }
</style>