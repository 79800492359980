import Vue from "vue";
import jslinq from "jslinq"
import _ from "lodash"
// import ajax from '../plugins/ajax_request'

export default {
  setFrontal(state, item) {
    state.loadingGeometry = true;
    state.frontale = item;
  },
  resetConfiguration(state, { forma = null, colore = null, coloreAste = null }) {
    state.calibro = "";
    state.naso = "";
    state.asianFitting = false;
    if (!_.isEmpty(colore)) {
      const c = jslinq(state.frontali.colors),
        cfd = c.firstOrDefault((col) => {
          return col.U_THE_COLORE == colore;
        });
      state.colore = cfd;
    }
    else {
      state.colore = "";
      // state.
    }
    state.finitura = "S";
    state.misuraAste = 145;
    if (!_.isEmpty(coloreAste)) {
      const ca = jslinq(state.aste.allColors),
        cad = ca.firstOrDefault((col) => {
          return col.U_THE_COLORE == "PL0001" && col.U_THE_LUNGASTA == state.misuraAste;
        });
      state.coloreAste = cad;
    }
    else { state.coloreAste = ""; }
    state.coloreMetalloAste = "";
    state.finituraAste = "S";
    state.nome = "";
    state.coloreNome = "";
    state.note = "";
    if (forma && !_.isEmpty(forma)) {
      const f = jslinq(state.frontali.items),
        fd = f.firstOrDefault(front => {
          return front.U_THE_MODELLO == forma;
        })
      // this.setFrontale(fd)
      state.frontale = fd
    }/* else {
      state.frontale = state.frontale;
    }*/
  },
  //TODO: eliminare funzione - DEPRECATA
  setFrontali(state, f) {
    state.frontali = f;
  },
  // setAste(state, a) {
  //   state.aste = a;
  // },
  setSize(state, size) {
    /*state.calibro = Math.ceil(size.size_eye);
    state.naso = Math.ceil(size.size_bridge);*/
    // let s = size.label.split("/");
    state.calibro = parseInt(size.size_eye);
    state.naso = parseInt(size.size_bridge);
  },
  setAsianFitting(state, af) {
    state.asianFitting = af;
  },
  setFinishing(state, f) {
    state.finitura = f;
  },
  setColore(state, c) {
    state.colore = c;
  },
  clearSize: (state) => {
    state.naso = "";
    state.calibro = "";
  },
  setTemplesColor(state, tc) {
    state.coloreAste = tc;
  },
  setTemplesMetalColor(state, c) {
    state.coloreMetalloAste = c;
  },
  unsetTemplesMetalColor(state) {
    state.coloreMetalloAste = null;
  },
  // setFinituraAste(state, f) {
  //   state.finituraAste = f;
  // },
  setTemplesFinishing(state, f) {
    state.finituraAste = f;
  },
  setTemplesMaterial(state, m) {
    state.templesMaterial = m;
  },
  setTemplesSize: (state, size) => {
    state.misuraAste = size;
  },
  setRowNotes: (state, note) => {
    state.note = note;
  },
  setName: (state, name) => {
    state.nome = name;
  },
  setColoreNome: (state, color) => {
    state.coloreNome = color;
  },
  startLoadingGeometry: (state) => {
    state.loadingGeometry = true;
  },
  stopLoadingGeometry: (state) => {
    state.loadingGeometry = false;
  },
  /*User mutations*/
  setUser(state, user) {
    state.user = user;
    Vue.cookie.set("_identity", state.user.auth_key, 30);
  },

  saveConfiguration: (state) => {
    let c = {
      frontale: state.frontale, //modello frontale
      calibro: state.calibro,
      naso: state.naso,
      asianFitting: state.asianFitting,
      colore: state.colore,
      finitura: state.finitura,
      coloreAste: state.coloreAste,
      coloreMetalloAste: state.coloreMetalloAste,
      finituraAste: state.finituraAste,
      misuraAste: state.misuraAste,
      nome: state.nome,
      coloreNome: state.coloreNome,
      note: state.note,
    };

    state.configurations.push(c);
  },
  restoreConfiguration: (state, id) => {
    let cfg = state.configurations[id];

    state.frontale = cfg.frontale; //modello frontale
    state.calibro = cfg.calibro;
    state.naso = cfg.naso;
    state.asianFitting = cfg.asianFitting;
    state.colore = cfg.colore;
    state.finitura = cfg.finitura;
    state.coloreAste = cfg.coloreAste;
    state.coloreMetalloAste = cfg.coloreMetalloAste;
    state.finituraAste = cfg.finituraAste;
    state.misuraAste = cfg.misuraAste;
    state.nome = cfg.nome;
    state.coloreNome = cfg.coloreNome;
    state.note = cfg.note;
  },
  restoreJsonConfiguration: (state, cfg) => {
    state.frontale = cfg.frontale; //modello frontale
    state.calibro = cfg.calibro;
    state.naso = cfg.naso;
    state.asianFitting = cfg.asianFitting;
    state.colore = cfg.colore;
    state.finitura = cfg.finitura;
    state.coloreAste = cfg.coloreAste;
    state.coloreMetalloAste = cfg.coloreMetalloAste;
    state.finituraAste = cfg.finituraAste;
    state.misuraAste = cfg.misuraAste;
    state.nome = cfg.nome;
    state.coloreNome = cfg.coloreNome;
    state.note = cfg.note;
  },
  removeConfiguration: (state, id) => {
    let fConfigurations = state.configurations.filter((cfg, index) => index != id);
    state.configurations = fConfigurations;
  },
  clearAllConfigurations(state) {
    state.configurations = [];
  },
  setConfirm(state) {
    state.confirm = !state.confirm;
  },
  setQuantity(state, q) {
    state.quantity = q;
  },
};
