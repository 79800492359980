<template>
  <el-dialog
    id="history-dialog"
    :visible="visible"
    fullscreen
    @close="$emit('close')"
    @open="updateHistory()"
  >
    <span slot="title" class="dialog-title"> <Storico /> {{ $t('message.STORICO_ORDINI') }} </span>
    <el-row id="filter-row" :gutter="15">
      <el-col :span="4">
        <!-- filtri select -->
        <el-select v-model="modello" clearable :placeholder="$t('message.Modello')" filterable>
          <el-option
            v-for="model in models"
            :key="model"
            :label="model"
            :value="model"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-select
          v-model="riferimento"
          clearable
          filterable
          :placeholder="$t('message.Riferimento')"
        >
          <el-option
            v-for="ref in references"
            :key="ref"
            :label="ref"
            :value="ref"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          v-model="coloreFrontale"
          clearable
          :placeholder="$t('message.Colore_frontale')"
          filterable
        >
          <el-option
            v-for="color in frontColors"
            :key="color"
            :label="color"
            :value="color"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-date-picker
          v-model="data"
          type="date"
          :placeholder="$t('message.Data')"
          format="dd/MM/yyyy"
          value-format="dd/MM/yyyy"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="4">
        <!-- filtro cerca -->
      </el-col>
    </el-row>
    <el-row class="table-row">
      <el-col :span="24">
        <el-table :data="rowsPage" border v-loading="loadingHistory">
          <el-table-column prop="date" :label="$t('message.Data')">
            <template slot-scope="scope">
              {{ scope.row.date | pretyDate }}
            </template>
          </el-table-column>
          <el-table-column prop="ID" :label="$t('message.Ordine')">
            <template slot-scope="scope"> #{{ scope.row.ID }} </template>
          </el-table-column>
          <el-table-column :label="$t('message.Quantità')">
            <template slot-scope="scope">
              {{ scope.row.details.length }}
            </template>
          </el-table-column>
          <el-table-column prop="Notes" :label="$t('message.Note')"></el-table-column>
          <el-table-column :label="$t('message.Status')">
            <template slot-scope="scope">
              {{ scope.row.row_state }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('message.Visualizza')">
            <template slot-scope="scope">
              <el-button type="text" @click="activeOrder = scope.row"
                >{{ $t('message.Visualizza') }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-pagination
      class="pagination-row"
      layout="total, prev, next"
      :total="filteredOrders.length"
      @prev-click="loadPage"
      @next-click="loadPage"
      :page-size="pageSize"
    >
    </el-pagination>
    <DetailsDialog
      :visible="activeOrder != null"
      v-if="activeOrder != null"
      @close="activeOrder = null"
      :order="activeOrder"
    />
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import jslinq from "jslinq";
import moment from "moment";
import _ from "lodash";

import Storico from "vue-material-design-icons/SortVariant.vue";
import DetailsDialog from "./history-dialog/DetailorderDialog.vue";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    pretyDate(d) {
      let md = moment(d, "YYYY-MM-DD hh:mm:ss");
      return md.format("DD/MM/YYYY");
    },
    // statusString(s) {
    //   let str = "";
    //   s.forEach((stato, i) => {
    //     if (i == 0) str += `${stato.row_state}`;
    //     else str += `/${stato.row_state}`;
    //   });

    //   return str;
    // },
  },
  components: {
    Storico,
    DetailsDialog,
  },
  data() {
    return {
      modello: "",
      riferimento: "",
      coloreFrontale: "",
      data: null,
      activeOrder: null,
      page: 1,
      pageSize: 8,
      rowsPage: [],
      loadingHistory: false,
    };
  },
  // async mounted() {
  //   // await this.getUserHistory();
  //   // this.loadPage(1);
  //   // this.loadingHistory = false;
  // },
  methods: {
    ...mapActions("user", ["getUserHistory"]),
    async updateHistory() {
      this.loadingHistory = true;
      await this.getUserHistory();
      this.loadPage(1);
      this.loadingHistory = false;
    },
    loadPage(e) {
      if (e != 1) {
        let start = (e - 1) * this.pageSize,
          end = start + this.pageSize;
        this.rowsPage = _.slice(this.filteredOrders, start, end);
        this.page = e;
      } else {
        this.rowsPage = _.slice(this.filteredOrders, 0, this.pageSize);
        this.page = e;
      }
    },
  },
  computed: {
    ...mapState("user", ["orderHistory"]),
    allDetails() {
      let det = [];
      this.orderHistory.forEach((element) => {
        element.details.forEach((d) => {
          det.push(d);
        });
      });

      return jslinq(det);
    },
    models() {
      return this.allDetails
        .orderBy((det) => {
          return det.U_THE_MODELLO;
        })
        .select((det) => {
          return det.U_THE_MODELLO;
        })
        .distinct()
        .toList();
    },
    references() {
      return this.allDetails
        .orderBy((det) => {
          return det.Note;
        })
        .select((det) => {
          return det.Note;
        })
        .distinct()
        .toList();
    },
    frontColors() {
      return this.allDetails
        .orderBy((det) => {
          return det.COLORE;
        })
        .select((det) => {
          return det.COLORE;
        })
        .distinct()
        .toList();
    },
    filteredOrders() {
      let o = jslinq(this.orderHistory);
      if (this.modello != "") {
        o = o.where((ord) => {
          let hasDetail = jslinq(ord.details).any((d) => {
            return d.U_THE_MODELLO == this.modello;
          });

          return hasDetail;
        });
      }
      if (this.riferimento != "") {
        o = o.where((ord) => {
          return jslinq(ord.details).any((d) => {
            return d.Note == this.riferimento;
          });
        });
      }

      if (this.coloreFrontale != "") {
        o = o.where((ord) => {
          return jslinq(ord.details).any((d) => {
            return d.COLORE == this.coloreFrontale;
          });
        });
      }

      if (this.data) {
        let md = moment(this.data, "DD-MM-YYYY");

        o = o.where((ord) => {
          return (
            moment(ord.date, "YYYY-MM-DD hh:mm:ss").isSame(md, "year") &&
            moment(ord.date, "YYYY-MM-DD hh:mm:ss").isSame(md, "month") &&
            moment(ord.date, "YYYY-MM-DD hh:mm:ss").isSame(md, "day")
          );
        });
      }

      return o.toList();
    },
  },
  watch: {
    filteredOrders: {
      deep: true,
      handler: function (newVal) {
        if (newVal.length % this.pageSize == 0 && this.page != 1) {
          this.loadPage(this.page - 1);
        } else {
          this.loadPage(this.page);
        }
      },
    },
  },
};
</script>

<style>
</style>